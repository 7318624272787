.hero__section {
  background: #da6d42
}
.hero__content h2 {
  color: var(--primary);
  font-size: 2.5rem;
  font-weight: 600;
  margin: 20px 0px;
}
.hero__content p {
  color: black;
  line-height: 20px;
}
.hero__section p:nth-child(1) {
  font-weight: 500;
}
.Buy__btn {
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 20px;
  background: var(--primary-color);
  color: rgb(155, 214, 178);
  cursor: pointer;
  font-size: 1rem;
  margin-top: 50px;
}
.Buy__btn a:hover {
  color: #fff;
}
.hero__content {
  padding-top: 45px;
}
.section__title {
  color: var(--primary-color);
  font-weight: 600;
}

.timer__count {
  background: var(--primary-color);
  height: 300px;
}
.timer__count img {
  width: 70% !important;
  height: 70% !important;
  object-fit: contain;
}
.store__btn {
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 20px;
  background: rgb(151, 204, 155);
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1rem;
  margin-top: 50px;
}
.store__btn a:hover {
  color: #fff
}

@media only screen and (max-width: 992px) {
  .hero__content h2 {
    font-size: 2rem;
  }
  .section__title{
    font-size: 1.7rem;
  }
  .counter__img{
    display: none;
  }
  .clock__top-content{
    text-align: center !important;
  }
  .clock__top-content h4{
    font-size: 0.9rem;
  }
  .clock__top-content h3{
    font-size: 1rem;
  }
  .count__down-col{
    text-align: center;
  }

}
@media only screen and (max-width: 768px) {
  .hero__content h2 {
    font-size: 1.6rem;
  }
  .hero__content p {
    font-size: 0.9rem;
  }
  .buy__btn {
    font-size: 0.9rem;
  }
  .section__title{
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 576px) {
  .hero__content {
    padding-top: 0;
    margin-bottom: 40px;
  }
}
