.auth__form{
    background: var(--primary-color);
    padding: 40px;
    border-radius: 5px;
}
.auth__btn{
    background: #fff !important;
    color: var(--primary-color);
    font-weight: 600;
}
.auth__form p{
margin-top: 30px;
}
.auth__form p a{
    color: #fff
}
.auth__form p a:hover{
    color: rgb(21, 141, 75);
}
.auth__form input[type="file"]{
    color: #fff;
    cursor: pointer;
}