
.footer{
padding-top: 60px;
background: var(--primary-color);
}

.footer__text{
    color: rgba(255, 255, 255, 0.795);
}
.footer__quick-links ul li {
    background: transparent !important;
}
.footer__quick-links ul li a{
    color: rgba(255, 255, 255, 0.795);
}
.quick__links-title{
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.1rem;
}
.footer__contact li span, .footer__contact li p{
    color: rgba(255, 255, 255, 0.795);

}
.footer__contact li p{
    font-size: 0.9rem;
}
.footer__copyright{
color: rgba(255, 255, 255, 0.795);
font-size: 0.9rem;
margin-top: 50px;
text-align: center;

}
.footer__quick-links ul li a:hover{
    color: #fff
}

.footer__contact li p a:hover{
    color: #fff;
}
