.product__item{
    cursor: pointer;
    border: 1px solid #e6dcdc;
}
.product__info h3 a{
  font-size: 1.2rem;
  color: var(--primary-color);
  font-weight: 400;
  margin-top: 15px;
}
.product__info a:hover{
    color: rgb(7, 167, 153);
}
.product__info span {
  font-size: 0.9rem;
}
.product__card-bottom .price {
  color: var(--primary-color);
  font-weight: 1.3rem;
  font-weight: 500;
}
.product__card-bottom span i {
    font-size: 1.3rem;
    padding: 5px;
    background: var(--primary-color);
    color: #fff;
    border-radius: 50px;
}
@media only screen and (max-width: 992px){
  .product__info h3 a{
    font-size: 1.1rem;
  }
}
@media only screen and (max-width:768px){
  .product__card-bottom span i{
    font-size: 1rem;
  }
}
.product__image{
  padding: 5px;
}