.billing__form .form__group {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 10px;
}
.checkout__cart {
  padding: 20px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 5px;
}
.checkout__cart h6,
h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout__cart h6 {
  margin-bottom: 20px;
}

.checkout__cart h4 {
  border-top: rgba(221, 221, 221, 0.251);
  padding-top: 20px;
}
.checkout__btn {
  background: #fff;
  color: var(--primary-color);
}
.country__list {
  width: 100%;
  border: 1px solid var(--primary-color);
  outline: none !important;
  border-radius: 5px;
}

.form-control {
  height: 45px !important;
  width: 100% !important;
  font-size: 0.9rem !important;
}
.react-tel-input {
  border-radius: 5px !important;
  border: 1px solid rgb(37, 37, 37);
  font-size: 0.9rem !important;
}

.flag-dropdown {
  background-color: rgb(185, 165, 165) !important;
}

.inputf:focus
{
border: 1.5px solid rgb(15, 159, 216) !important;
outline-style: rgb(15, 159, 216) !important;
outline-width: 1px !important;
outline: 1px solid rgb(17, 151, 192) !important;
}
.react-tel-input:focus{
  border: 1px solid rgb(15, 159, 216) !important;
  outline-width: 1px !important;
}
.order__btn{
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 20px;
  background: var(--primary-color);
  color: rgb(155, 214, 178);
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  margin-left: 90px;
  width: 50%;
}
.order__btn:hover{
  color: #fff;
}